exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-words-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/words/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-words-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-words-tsx": () => import("./../../../src/pages/words.tsx" /* webpackChunkName: "component---src-pages-words-tsx" */)
}

